import React, {useEffect, useState} from 'react';
import {Container, Form} from "react-bootstrap";

const LoginModal = () => {
    //Call API
    const [buy, setBuy] = useState([]);
    const [error, setError] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    //Input info
    const [coins, setCoins] = useState('')
    const [email, setEmail] = useState('')
    const [cost, setCost] = useState('')
    //Validation Form
    const [coinsDirty, setCoinsDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [coinsError, setCoinsError] = useState('Количество монет не может быть пустым')
    const [emailError, setEmailError] = useState('Email не может быть пустым')
    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        if (coinsError || emailError) {
            setFormValid(false)
        } else {
            setError(null)
            setFormValid(true)
        }
    }, [coinsError, emailError])

    useEffect(() => {
        if (error) {
            setFormValid(false);
        }
    }, [error])

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Не корректный Email')
            if (!e.target.value) {
                setEmailError('Email не может быть пустым')
            }
        } else {
            setEmailError('')
        }
    }

    const coinsHandler = (e) => {
        setCoins(e.target.value)
        if (e.target.value < 10 || e.target.value > 200) {
            setCoinsError('Монет должно быть больше 10 и меньше 200')
            if (!e.target.value) {
                setCoinsError('Значение монет не может быть пустым')
            }
        } else {
            setCoinsError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.id) {
            case 'Email' :
                setEmailDirty(true)
                break
            case 'Coins' :
                setCoinsDirty(true)
                break
        }
    }

    const click = () => {
        setLoading(true)
        const data = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "email": email,
                "coins": coins
            })
        }
        fetch('https://omega.rigilkentaurus.tk/api/buy', data)
            .then(r => {
                if (r.status === 200)
                    return r.json();
                else error('Произошла ошибка 2');
            })
            .then(response => {
                setBuy(response)
            })
            .catch(error => {
                console.error('Произошла ошибка 3', error)
                setError(error)
                setLoading(false)
            })
    }
    //Приложение (проверка на регистрацию)
    /*const token = sessionStorage.getItem('token');
    {(token && token!='' && token!=undefined) ? 'У вас есть ключ' + token : } */

    return (
        <div className="modal fade" id="LoginModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog justify-content-center">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Пополнить коины</h1>
                    </div>
                    <Container className="d-flex text-center justify-content-center text-align-center">
                        <Form className="form-signin w-20" style={{width: 370, padding: 30}}>
                            <div className="form-floating">
                                <input type="email"
                                       className="form-control"
                                       id="Email"
                                       placeholder="name@example.com"
                                       value={email}
                                       onBlur={e => blurHandler(e)}
                                       onChange={e => emailHandler(e)}/>
                                <label htmlFor="Email">Email адрес</label>
                                <div className="form-floating mt-1">
                                    <input type="number"
                                           className="form-control"
                                           id="Coins"
                                           autoComplete="on"
                                           placeholder="10"
                                           value={coins}
                                           onBlur={e => blurHandler(e)}
                                           onChange={e => coinsHandler(e)}/>
                                    <label htmlFor="Coins">Колличество коинов</label>
                                </div>
                                <div className="form-floating mt-1">
                                    <input type="price"
                                           className="form-control"
                                           id="Price"
                                           autoComplete="on"
                                           readOnly={true}
                                           value={coins * 2}
                                           onChange={e => setCost(e.target.value)}/>
                                    <label htmlFor="Price">Цена</label>
                                </div>
                                <div className="checkbox mb-3 mt-3">
                                    <label>
                                        <input className="m-1" type="checkbox" value="remember-me"/>
                                        Подписаться на обновления
                                    </label>
                                </div>
                                {(emailDirty && emailError || coinsDirty && coinsError) &&
                                    <div className="form-group">
                                        <div className="alert alert-danger">
                                            <strong>Error!</strong> {emailError || coinsError}
                                        </div>
                                    </div>
                                }
                                {error &&
                                    <div className="form-group">
                                        <div className="alert alert-danger">
                                            <strong>Error!</strong> Непредвиденная ошибка
                                        </div>
                                    </div>
                                }
                                {showSuccess &&
                                    <div className="form-group">
                                        <div className="alert alert-success">
                                            <strong>Success!</strong> {buy.data}
                                        </div>
                                    </div>
                                }
                                <button className="w-100 btn btn-lg btn-warning"
                                        disabled={!formValid || loading}
                                        type="button"
                                        onClick={click}>
                                    {loading ? 'Ожидайте...' : 'Оплатить'}
                                </button>
                            </div>
                        </Form>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default LoginModal;