import React, {useState, useEffect} from 'react';

const AccessModal = () => {

    const [token, setToken] = useState('')
    const [error, setError] = useState(null)
    const [access, setAccess] = useState(false)
    const [showAccess, setShowAccess] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (token) {
            setAccess(true)
            setError(null)
        }
    }, [token])

    useEffect(() => {
        if (error) {
            setAccess(false);
        }
    }, [error])

    const click = () => {
        setLoading(true)
        const data = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'token': token
            })
        }
        fetch('https://omega.rigilkentaurus.tk/api/access', data)
            .then(r => {
                if (r.status === 200)
                    return r.json();
                else error('Неверный токен входа');
            })
            .then(response => {
                setAccess(response)
                setShowAccess(true)
                localStorage.setItem('data', response.data)
                localStorage.setItem('coins', response.coins)
            })

            .catch(error => {
                setError(error)
                setLoading(false)
                console.error('Неверный токен входа 2', error)
            })
    }
    //Приложение (проверка на регистрацию)
    /*const token = sessionStorage.getItem('token');
    {(token && token!='' && token!=undefined) ? 'У вас есть ключ' + token : } */

    return (
        <div className="modal fade" id="Access" tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Получить доступ</h1>
                    </div>
                    <div className="modal-body">
                        <form>
                            Если вы уже покупали коины, то на вашу почту должно было прийти сообщение, в котором будет
                            указан набор букв и чисел для получения доступа, введите их в это поле:
                            <div className="input-group mt-3 mb-2">
                                <input type="text"
                                       className="form-control"
                                       placeholder="Ваш токен с Email"
                                       aria-label="Recipient's username"
                                       aria-describedby="button-addon2"
                                       value={token}
                                       onChange={e => setToken(e.target.value)}/>
                                <button className="btn btn-warning" type="button" onClick={click}
                                        id="button-addon2" disabled={!access || loading}>
                                    {loading ? 'Ожидайте...' : 'Получить'}
                                </button>
                            </div>
                            {error &&
                                <div className="form-group">
                                    <div className="alert alert-danger">
                                        <strong>Error!</strong> Не верный токен входа
                                    </div>
                                </div>}
                            {showAccess &&
                                <div className="form-group">
                                    <div className="alert alert-success">
                                        <strong>Success!</strong> Email:{access.data} Coins:{access.coins}
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessModal;