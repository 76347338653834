import React, {Component} from 'react';
import Extract from '../assets/Extract_table.png'
import {Button, Spinner} from "react-bootstrap";

class FileLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, // to keep track of when form submitted
            errors: null, // for displaying errors
            file: null, // the file type the user chooses to download
            imagePreview: '',// превью файла при загрузке1
            /*token: '' //Токен авторизованного или не авторизованного клиента */
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(e, targetformat) {
        e.preventDefault();
        const url = 'https://omega.rigilkentaurus.tk/api/file_convert';
        const formData = new FormData();
        //работа с формами в реакте
        formData.append('file', this.state.file);
        formData.append('targetformat', targetformat);
        /*formData.append('token', localStorage('token'));*/

        this.setState({
            loading: true
        })
        fetch(url, {method: 'POST', body: formData})
            .then(response => {
                if (response.status !== 200)
                    throw response
                response
                    .blob()
                    .then(blob => {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            /*link.setAttribute('download',
                                this.state.file.name.substring(0, this.state.file.name.lastIndexOf('.')) + ".xls");*/
                            document.body.appendChild(link);
                            link.click();

                            // Cleanup
                            window.URL.revokeObjectURL(link.href);
                            document.body.removeChild(link);
                        }
                    )

            })
            .catch((error) => {
                /*error.json().then((json) => {
                    this.setState({
                        errors: json
                    })
                })*/
                this.setState({
                    errors: {message: 'Не правильная форма файла'}
                })
            })
            .finally(() =>
                this.setState({
                    loading: false
                })
            )
    }

    handleChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
        /*reader.readAsDataURL(file)*/
    }

    render() {
        const {errors} = this.state;

        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} alt={imagePreviewUrl} height='150' width='150'/>);
        } else {
            $imagePreview = (<div className="previewText text-secondary">Выберите файл для предпросмотра</div>);
        }

        return (
            <div className="container py-3 justify-content-center" id='loader'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-12">
                            <header className="App-header">
                                <h1 className="display-4 mb-4 text-center">
                                    Извлечение табличных данных и текста из изображений
                                </h1>
                                <p className='text-secondary text-center'>Нажмите на любой из форматов файла и выберете
                                    необходимый для обработки файл.</p>
                            </header>
                            <main>
                                <form /*onSubmit={this.handleSubmit}*/
                                    onSubmit={(e) => this.handleSubmit(e)}>
                                    <label className='mx-auto my-2 my-sm-3 my-lg-4 p-3 text-center'
                                           htmlFor='input_file'
                                           style={{
                                               cursor: "pointer", outlineColor: "#AB47BC",
                                               outlineStyle: "dashed", outlineWidth: 2, borderRadius: '20px'
                                           }}>
                                        <img src={Extract}
                                             alt={Extract}
                                             style={{width: '45%', height: 'auto'}}/>
                                    </label>
                                    <div className="input-group">
                                        <input id='input_file'
                                            /*onChange={this.handleChange}*/
                                               onChange={(e) => this.handleChange(e)}
                                               className="form-control outline-border-width d-none"
                                               type="file" accept=".pdf,.jpeg,.png"
                                               name="file"
                                               placeholder="File type, ex csv, pdf, png, etc"
                                               autoComplete="off"/>
                                    </div>
                                    {(errors)
                                        ? (<div className="form-group">
                                                <div className="alert alert-danger">
                                                    <strong>Error!</strong> {errors.message || 'Что то пошло не так'}
                                                </div>
                                            </div>
                                        )
                                        : null
                                    }
                                    <div className="imgPreview text-center">
                                        {$imagePreview}
                                    </div>
                                    <div className="form-group text-center mt-3">
                                        {/*<button disabled={!this.state.file || this.state.loading} type="submit"
                                            onClick={this.handleSubmit}
                                                onClick={(e) => this.handleSubmit(e)}
                                                className="btn btn-warning">
                                            {this.state.loading ? "Ожидайте загрузки" : 'Скачать .xls'}
                                        </button>*/}
                                        <div className="btn-group">
                                            <button type="button"
                                                    className="btn btn-warning dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    disabled={!this.state.file || this.state.loading}>
                                                {this.state.loading
                                                    ?
                                                    <Button variant="warning" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    Ожидайте загрузки...
                                                    </Button>
                                                    :
                                                    'Скачать файл'}
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item"
                                                       onClick={(e) => this.handleSubmit(e, 'xlsx')}>
                                                        exсel
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"
                                                       onClick={(e) => this.handleSubmit(e, 'txt')}>
                                                        text
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"
                                                       onClick={(e) => this.handleSubmit(e, 'docx')}>
                                                        word
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"
                                                       onClick={(e) => this.handleSubmit(e, 'pdf')}>
                                                        pdf
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileLoader;