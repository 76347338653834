import React from 'react';
import 'react-bootstrap';
import {Nav} from "react-bootstrap";
import ConnectModal from "../modal/ConnectModal";

const Footer = () => {
    return (
        //*fixed-bottom*/ = for stay down
        <footer className="py-0 mt-5 margin-top container">
            <Nav className="nav justify-content-center border-bottom pb-3 mb-3">
                <li className="nav-item">
                    <a href={"#prices"} className="nav-link px-2 js-scroll-trigger text-muted">Цены</a>
                </li>
                <li className="nav-item">
                    <a href={"#Description"} className="nav-link px-2 text-muted">Преимущества</a>
                </li>
                <li className="nav-item">
                    <a data-bs-toggle="modal"
                       data-bs-target={"#Connect"}
                       href={'#Connect'}
                       className="nav-link px-2 text-muted"
                       style={{cursor: "pointer"}}>Связаться с нами</a>
                </li>
                <li className="nav-item"><a href={"#FAQ"} className="nav-link px-2 text-muted">FAQs</a></li>
            </Nav>
            <ConnectModal/>
            <p className="text-center text-muted">&copy; 2022 Company, Inc</p>
        </footer>

    );
};

export default Footer;