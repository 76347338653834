import React from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import "react-bootstrap";
import {BrowserRouter} from "react-router-dom";
import './App.css';

import NavBar from "./components/NavBar";
import ExtractTable from "./components/ExtractTable";
import Footer from "./components/Footer";
import Prices from "./components/Prices";
import PricesDescription from "./components/PricesDescription";
import Faq from "./components/FAQ";

const App = () => {

    return (
        <BrowserRouter>
            <NavBar/>
            <ExtractTable/>
            <Prices/>
            <PricesDescription/>
            <Faq/>
            <Footer/>
        </BrowserRouter>
    );
};

export default App;