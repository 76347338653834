import React, {Component} from 'react';

class PricesDescription extends Component {
    render() {
        return (
            <div className="container py-3 justify-content-center" id='Description'>
                <h2 className="display-6 text-center mb-4">Преимущества платной версии</h2>
                <table className="table table-hover">
                    <thead className="table-warning">
                    <tr>
                        <th scope="col" className='col-md-4'>Версия:</th>
                        <th scope="col" className='text-center col-md-4'>Бесплатная</th>
                        <th scope="col" className='text-center col-md-4'>Платная</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">% Распознавания</th>
                        <td className='text-center'>70%</td>
                        <td className='text-center'>90%</td>
                    </tr>
                    <tr>
                        <th scope="row">Распознавание таблиц</th>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{color: '#14A44D'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                        </td>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{color: '#14A44D'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Таблицы и текст</th>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-x-circle-fill" viewBox="0 0 16 16" style={{color: '#DC4C64'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </td>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{color: '#14A44D'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Личное хранилище</th>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-x-circle-fill" viewBox="0 0 16 16" style={{color: '#DC4C64'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </td>
                        <td className='text-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{color: '#DC4C64'}}>
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PricesDescription;