import React from 'react';
import 'react-bootstrap';
import LoginModal from "../modal/LoginModal";

const Prices = () => {
    return (
        <div className="container py-3 justify-content-center" id='prices'>
            <div className="row row-cols-1 row-cols-md-2 mb-3 text-center container-fluid mt-3">
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Бесплатное использование</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">₽0<small
                                className="text-muted fw-light">/файл</small></h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>Перевод фотографии в редактируемый формат</li>
                                <li>Конвертация графических изображений</li>
                                <li>Конвертация документов с текстом</li>
                                <li>Пробное использование ограниченно</li>
                            </ul>
                            <button type="button" className="w-100 btn disabled btn-lg btn-outline-dark">
                                Попробовать бесплатно
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm border-#AB47BC">
                        <div className="card-header py-3 text-bg-#AB47BC bg-gradient"
                             style={{backgroundColor: '#AB47BC'}}>
                            <h4 className="my-0 fw-normal" style={{color: '#FFF8E1'}}>Платное использование</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">₽2<small
                                className="text-muted fw-light">/файл</small></h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>Улучшенное распознавание</li>
                                {/*<li>Личное хранилище файлов</li>*/}
                                <li>Оптическое распознавание текста</li>
                                <li>Конвертация напрямую с фотокамеры</li>
                                <li>Круглосуточная поддержка</li>
                            </ul>
                            <button type="button" className="w-100 btn btn-lg btn-warning" data-bs-toggle="modal"
                                    data-bs-target={"#LoginModal"}>
                                Купить использования
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal/>
        </div>
    );
};

export default Prices;