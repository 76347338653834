import React from 'react';
import Logo from '../assets/Gernet_custom_logo.png'
import AccessModal from "../modal/AccessModal";
import ConnectModal from "../modal/ConnectModal";

const NavBar = () => {
    return (
        <nav className="navbar navbar-sticky-top navbar-expand-lg bg-gradient" style={{backgroundColor: '#AB47BC'}}>
            <div className="container">
                <a className="navbar-brand" style={{color: '#FFF8E1', cursor: "default"}}>
                    <img src={Logo} alt={Logo} width="30" height="24"
                         className="logo d-inline-block align-text-top pl-1 me-1"/>
                    File Converter
                </a>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarText"
                        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href={"#prices"}
                               style={{color: '#FFF8E1'}}>Цены</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active"
                               data-bs-toggle="modal"
                               data-bs-target={"#Connect"}
                               style={{color: '#FFF8E1', cursor: "pointer"}}
                            >
                                Связаться с нами
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href={"#FAQ"} style={{color: '#FFF8E1'}}>FAQ</a>
                        </li>
                    </ul>
                    <form className='margin-left'>
                        <button className="btn btn-warning me-2" type="button" data-bs-toggle="modal"
                                data-bs-target={"#Access"}>
                            Получить доступ
                        </button>
                    </form>
                </div>
            </div>
            <ConnectModal/>
            <AccessModal/>
        </nav>

    );
};

export default NavBar;