import React from 'react';

const ConnectModal = () => {
    return (
        <div className="modal fade" id="Connect" tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Связаться с нами</h1>
                    </div>
                    <div className="modal-body">
                        <form>
                            Если у вас появились вопросы, вы можете написать нам на Email@.com
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectModal;