import React from 'react';

const Faq = () => {
    return (
        <div className="container py-3 justify-content-center" id='FAQ'>
            <h2 className="display-6 text-center mb-4">Ответы на интересующе вопросы</h2>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>
                    Что такое кредиты?
                </h5>
                <div className="card-body">
                    <p className="card-text">Чтобы извлечь таблицы из изображений (JPG, JPEG, PNG) или PDF-файлов, вам
                        нужен ключ API
                        с кредитами, связанными с ним. За каждое успешно обработанное изображение или страницу PDF
                        тратится один балл. Плохие извлечения имеют право на возврат кредита. Плата не будет взыматься
                        за неудавшиеся транзакции.</p>
                    <p className='mt-5 card-text'>Расчет потребления кредитов</p>
                    <p className="card-text">- 1 кредит будет использован для обработки 1 изображения</p>
                    <p className='card-text'>- 7-страничный PDF будет потреблять 7 кредитов</p>
                    <p className='card-text'>Итак, если вам нужно преобразовать 100 изображений, вы должны приобрести
                        100 кредитов.</p>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>Как купить
                    больше кредитов?</h5>
                <div className="card-body">
                    <p className="card-text">В данный момент у нас есть ограничения по покупке кредитов. Кредитов не
                        должно быть меньше 10 и больше 200.</p>
                    <p className="card-text">Чтобы приобрести более 200 кредитов, пожалуйста, напишите
                        email@fileconverter.com</p>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>Какие файлы
                    поддерживаются?</h5>
                <div className="card-body">
                    <p className="card-text">Демо поддерживает только изображения (JPEG, JPG, PNG).</p>
                    <p className="card-text">Купленные кредиты также могут обрабатывать PDF вместе с изображениями (JPEG, JPG, PNG).</p>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>Какой
                    список поддерживаемых языков?</h5>
                <div className="card-body">
                    <p className="card-text">Русский и Английский</p>
                    <p className="card-text">Мы можем поддерживать другие языки для компаний (через сделку B2B), если ваш
                        ежемесячный объем не менее 10 000 страниц.</p>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>У вас есть
                    скидки?</h5>
                <div className="card-body">
                    <p className="card-text">Разумеется, на кредиты свыше 10 000 предоставляются скидки - обращайтесь
                        email@fileconverter.com. Цена снижается при покупке большего количества кредитов.</p>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header text-bg-#E1BEE7 bg-gradient" style={{backgroundColor: '#E1BEE7'}}>Другой
                    вопрос?</h5>
                <div className="card-body">
                    <p className="card-text">Наша команда Omega Team будет рада ответить на ваши вопросы. Пожалуйста, напишите
                        нам на email@fileconverter.com</p>
                </div>
            </div>
        </div>
    );
};

export default Faq;